<template>
  <div class="login">
    <div class="loginBox">
      <div class="leftBox">
        <div class="logo">
          <div class="logoImg"></div>
        </div>
        <div class="left_img">
          <img src="../../assets/loginIcon.png" alt="" />
        </div>
      </div>
      <div class="form">
        <h1>欢迎来到服务商后台。</h1>
        <h2>账号</h2>
        <Input
          class="inputs"
          v-model="formData.username"
          placeholder="服务商后台账号"
          size="default"
        ></Input>
        <h2>密码</h2>
        <Input
          class="inputs"
          type="password"
          password
          v-model="formData.password"
          placeholder="密码"
          size="default"
        ></Input>
        <h2>验证码</h2>
        <Input
          class="inputs min"
          maxlength="6"
          v-model="formData.validateCode"
          placeholder="验证码"
          size="default"
        ></Input>
        <span class="img_code" @click="getCode(true)">
          {{ timeOut1 == 0 ? "获取验证码" : `倒计时${timeOut1}s` }}
        </span>
        <div class="button" @click="login">登录</div>
        <div class="setUser">
          <div>
            <Checkbox v-model="checkUser" size="small">记住账号</Checkbox>
          </div>
          <div @click="setPass">忘记密码?</div>
        </div>
      </div>
    </div>
    <Modal
      v-model="passShow"
      title="忘记密码"
      closable
      class-name="loginBoxDoalog"
      footer-hide
      @on-cancel="passShow = false"
    >
      <div class="loginBox" style="width: 100%">
        <div class="form">
          <h2 style="margin-top: 0px"><i>*</i>账户名</h2>
          <Input
            class="inputs"
            v-model="setPsssform.username"
            placeholder="输入账户名"
            maxlength="16"
            size="default"
          ></Input>
          <h2><i>*</i>短信验证码</h2>
          <Input
            class="inputs min"
            maxlength="6"
            v-model="setPsssform.validateCode"
            placeholder="输入验证码"
            size="default"
          ></Input>
          <span class="img_code" @click="getCode(false)">{{
            timeOut == 0 ? "获取验证码" : `倒计时${timeOut}s`
          }}</span>
          <h2><i>*</i>新密码</h2>
          <Input
            class="inputs"
            type="password"
            password
            v-model="setPsssform.newPassword"
            placeholder="输入新密码"
            size="default"
          ></Input>
          <h2><i>*</i>重复新密码</h2>
          <Input
            class="inputs"
            type="password"
            password
            v-model="setPsssform.rePassword"
            placeholder="重复输入新密码"
            size="default"
          ></Input>
          <div class="passShowBtn">
            <Button class="exitBt" @click="passShow = false">取 消</Button>
            <Button class="addBt" @click="editPass">确 定</Button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import "@../../../src/utils/flexible";
import { isLogin, resetpwd, validatecode } from "@/api/login.js";
import md5 from "js-md5";
import { URL_API } from "@/utils/apiUrl.js";
import roles from "@/utils/newsRoles.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      formData: {
        username: "", //用户名
        password: "", //密码
        // randCode: "", //验证码
        validateCode: "", //验证码
      },
      setPsssform: {
        username: "",
        validateCode: "",
        newPassword: "",
        rePassword: "",
      },
      imageCode: URL_API.codeUrl,
      imageCodeRandom: "",
      passShow: false,
      timeOut: 0,
      codeShow: "",
      checkUser: false,
      timeOut1: 0,
      roles: roles,
    };
  },
  created() {
    if (localStorage.getItem("username")) {
      this.formData.username = localStorage.getItem("username");
      this.checkUser = true;
    }
    this.reImageCode();
    let that = this;
    document.onkeydown = function (e) {
      let key = window.event.keyCode;
      if (key === 13 && that.$route.name === "login") {
        that.login(); //方法
      }
    };
  },
  methods: {
    ...mapMutations({
      //档案中心
      file_setBusiness: "file_setBusiness",
      file_setAccount: "file_setAccount",
      //余额账户管理
      trading_setZhlb: "trading_setZhlb",
      trading_setentrySear: "trading_setentrySear",
      trading_setRefund_sg: "trading_setRefund_sg",
      trading_setTransfer_zz: "trading_setTransfer_zz",
      //系统管理
      system_setRoleQuery: "system_setRoleQuery",
      system_setStaffQuery: "system_setStaffQuery",
    }),
    // 重置验证码
    reImageCode() {
      this.imageCodeRandom = Math.random();
      this.imageCode = URL_API.codeUrl + "?num=" + this.imageCodeRandom;
    },
    // 登录
    login() {
      let data = JSON.parse(JSON.stringify(this.formData));
      // data.num = JSON.stringify(this.imageCodeRandom);
      if (!data.username) {
        this.$Message.error("服务商后台账号不能为空！");
        return;
      }
      if (!data.password) {
        this.$Message.error("密码不能为空！");
        return;
      }
      if (!data.validateCode) {
        this.$Message.error("验证码不能为空！");
        return;
      }
      // if (!data.randCode) {
      //   this.$Message.error("验证码不能为空！");
      //   return;
      // }
      if (this.checkUser) {
        localStorage.setItem("username", data.username);
      } else {
        localStorage.removeItem("username");
      }
      data.password = md5(data.password);
      isLogin(data).then((res) => {
        if (res.resultStatus) {
          this.$store.state.isActiveHead = true;
          sessionStorage.setItem("userInfo", JSON.stringify(res.resultData));
          sessionStorage.setItem("tabs", JSON.stringify([]));
          sessionStorage.setItem("active_tab", "");
          this.roles = res.resultData.isUniformTreatment
            ? this.roles[0].child
            : this.roles[0].child.map((item) => {
                return {
                  ...item,
                  child: item.child.filter((childItem) => {
                    return (
                      childItem.value !== "BASIC:BALANCE:TRANSFER" &&
                      childItem.value !== "BASIC:BALANCE:SETTLE"
                    );
                  }),
                };
              });
          this.$store.commit("setRolesData", this.roles);
          this.$store.commit("setToken", res.resultData.token);
          this.newDate();
          this.$router.replace({ path: "/" });
        } else {
          this.reImageCode();
        }
      });
    },
    newDate() {
      //档案中心
      this.file_setBusiness(null);
      this.file_setAccount(null);
      //余额账户管理
      this.trading_setZhlb(null);
      this.trading_setentrySear(null);
      this.trading_setRefund_sg(null);
      this.trading_setTransfer_zz(null);
      //系统管理
      this.system_setRoleQuery(null);
      this.system_setStaffQuery(null);
    },
    setPass() {
      this.setPsssform = {
        username: "",
        validateCode: "",
        newPassword: "",
        rePassword: "",
      };
      this.timeOut = 0;
      this.passShow = true;
    },
    getCode(active) {
      // 点击主页获取验证码时
      if (active) {
        if (this.timeOut1 != 0) {
          return;
        }
        if (!this.formData.username) {
          this.$Message.error("请输入服务商后台账号");
          return;
        }
        let data = {
          username: this.formData.username,
        };
        this.timeOut1 = 60;
        validatecode(data).then((res) => {
          if (res) {
            if (res.errorCode != null) {
              clearInterval(this.codeShow1);
              this.timeOut1 = 0;
            } else {
              this.codeShow1 = setInterval(() => {
                if (this.timeOut1 == 0) {
                  clearInterval(this.codeShow1);
                } else {
                  this.timeOut1--;
                }
              }, 1000);
            }
          } else {
            clearInterval(this.codeShow1);
            this.timeOut1 = 0;
          }
        });
      } else {
        //点忘记密码的获取验证码时
        if (this.timeOut != 0) {
          return;
        }
        if (!this.setPsssform.username) {
          this.$Message.error("请填写账户名");
          return;
        }
        let data = {
          username: this.setPsssform.username,
        };
        this.timeOut = 60;
        validatecode(data).then((res) => {
          if (res) {
            if (res.errorCode != null) {
              clearInterval(this.codeShow);
              this.timeOut = 0;
            } else {
              this.codeShow = setInterval(() => {
                if (this.timeOut == 0) {
                  clearInterval(this.codeShow);
                } else {
                  this.timeOut--;
                }
              }, 1000);
            }
          } else {
            clearInterval(this.codeShow);
            this.timeOut = 0;
          }
        });
      }
    },
    editPass() {
      let data = JSON.parse(JSON.stringify(this.setPsssform));
      let passwordTest =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
      if (!data.username) {
        this.$Message.error("账户名必填");
        return;
      }
      if (!data.validateCode) {
        this.$Message.error("验证码必填");
        return;
      }
      if (!data.newPassword && !data.rePassword) {
        this.$Message.error("密码必填");
        return;
      }
      // 正则验证密码复杂度
      if (!passwordTest.test(data.newPassword)) {
        return this.$Message.error(
          "密码必须包含：大小写字母, 数字, 特殊符号, 长度至少8位以上"
        );
      }
      if (data.newPassword != data.rePassword) {
        this.$Message.error("重复密码不相同");
        return;
      }
      data.newPassword = md5(data.newPassword);
      delete data.rePassword;
      resetpwd(data).then((res) => {
        if (res.resultStatus) {
          this.passShow = false;
          this.$Message.success("密码修改成功");
        }
      });
    },
  },
};
</script>

<style scoped>
/* 1px / 159.4992 = xx rem */
/deep/ .ivu-input {
  height: 0.3009rem !important;
  line-height: 0.3009rem !important;
  background-color: rgb(248, 248, 250);
  font-size: 0.0878rem !important;
  padding: 0 15px;
}
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../assets/loginBg.png) no-repeat center 50% / cover;
}

.loginBox {
  width: 6.6458rem;
  height: 3.9499rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #fff;
  border-radius: 10px;
  padding: 0.3135rem 0.1881rem;
  box-sizing: border-box;
}
.loginBox .leftBox {
  width: 50%;
}

.loginBox .leftBox .logo .logoImg {
  width: 1.5674rem;
  height: 0.2517rem;
  margin-bottom: 0.627rem;
  background-image: url(../../assets/logox-h.png);
  /* background-image: url(../../assets/logox.png); */
  background-size: 100% 100%;
}

.loginBox .leftBox .logo:hover .logoImg {
  /* background-image: url(../../assets/logox-h.png); */
}
.loginBox .leftBox .left_img img {
  width: auto;
  max-width: 100%;
}
.form {
  width: 35%;
}

.form h1 {
  color: #000000;
  font-size: 0.1505rem;
  font-weight: 400;
  font-family: "MicrosoftYaHei";
  margin-bottom: 0.1881rem;
}

.form h2 {
  margin-top: 0.1567rem;
  color: var(--title);
  font-size: 0.1003rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
}

.form h2 i {
  color: var(--themeColor);
  padding-right: 3px;
}
.form .inputs {
  margin-top: 0.0878rem;
  width: 100%;
}

.form .inputs.min {
  width: calc(100% - 37.718% - 20px);
}

.form .img_code {
  float: right;
  margin-top: 0.0878rem;
  display: inline-block;
  width: 37.718%;
  height: 0.3009rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.1003rem;
  line-height: 0.3009rem;
  color: #ffffff;
  background-color: var(--themeColor);
  text-align: center;
}

.form .button {
  margin-top: 0.2508rem;
  height: 0.3009rem;
  background-color: var(--themeColor);
  border-radius: 4px;
  line-height: 0.3009rem;
  text-align: center;
  font-size: 0.1129rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.form .setUser {
  margin-top: 0.1003rem;
  height: 0.2508rem;
  line-height: 0.2508rem;
  font-size: 0.0878rem;
}

.form .setUser div:first-child {
  float: left;
  cursor: pointer;
}

.form .setUser .el-checkbox {
  color: #696969;
}

.form .setUser div:last-child {
  float: right;
  color: var(--themeColor);
  cursor: pointer;
}
/deep/ .loginBoxDoalog .ivu-modal {
  width: 3.07213rem !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  margin-top: 0;
}

.loginBoxDoalog .form {
  width: 81.4%;
}
.loginBoxDoalog .loginBox {
  padding: 0.08rem 0.1881rem 0.1881rem;
  width: 100%;
  height: auto;
  margin-top: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 0.0878rem;
  line-height: 36px;
  color: var(--label);
  text-align: left;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  width: 200px;
  margin: 0 auto;
}

.passShowBtn {
  display: flex;
  justify-content: space-between;
  margin-top: 0.1567rem;
}
.passShowBtn .addBt {
  width: 1.0345rem;
  height: 0.3009rem !important;
  background-color: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
  color: #ffffff !important;
  font-size: 0.1129rem;
  padding: 0;
}

.passShowBtn .exitBt {
  width: 1.0345rem;
  height: 0.3009rem !important;
  background-color: #ffffff !important;
  color: var(--title) !important;
  border-color: #d7dae2 !important;
  font-size: 0.1129rem;
  padding: 0;
}

.overAccount_ul_title i {
  color: var(--themeColor);
  padding-right: 3px;
}

.getCode {
  display: inline-block;
  width: 0.4389rem;
  margin-left: 10px;
  color: var(--themeColor);
  cursor: pointer;
}
.login .form .inputs input {
  height: 0.2884rem;
  border: 0;
  padding: 0;
  border-bottom: 1px solid #e2e2e2;
  border-radius: 0;
}

.login .form .inputs input::-webkit-input-placeholder {
  font-size: 0.0878rem;
}

/deep/ .el-checkbox__label,
/deep/ .el-checkbox {
  font-size: 0.0878rem !important;
}
.login .form .setUser .el-checkbox__input:not(.is-checked) .el-checkbox__inner {
  width: 0.0878rem;
  height: 0.0878rem;
  border-radius: 0;
  border-color: #696969 !important;
  background-color: #fff;
}

@media screen and (max-width: 784px) {
  .loginBox .leftBox .left_img {
    display: none;
  }
  .loginBox {
    width: 80%;
    flex-flow: wrap;
    height: auto;
    padding: 0.25rem 0.1881rem;
  }
  /deep/ .loginBoxDoalog {
    width: 80%;
  }
  .loginBoxDoalog .loginBox {
    width: 100%;
  }
  .loginBoxDoalog .form {
    width: 100%;
  }
  .loginBox .leftBox .logo .logoImg {
    margin-bottom: 0.1881rem;
  }
  .loginBoxDoalog .loginBox .leftBox .logo .logoImg {
    margin: 0.08rem 0rem 0.1881rem 0rem;
  }
  .form,
  .loginBox .leftBox .left_img,
  .loginBox .leftBox {
    width: 100%;
  }
}
</style>
